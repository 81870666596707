<template>
  <v-dialog
      :value="visible"
      max-width="400"
      persistent
  >
    <v-card>
      <v-container>
        <v-card-title class="heading">
          Вы действительно хотите удалить?
        </v-card-title>

        <!--      <v-card-text>-->
        <!--        Вы действительно хотите удалить?-->
        <!--      </v-card-text>-->

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="blue darken-1"
              text
              @click="decline"
          >
            Нет
          </v-btn>

          <v-btn
              color="blue"
              outlined
              @click="confirm"
          >
            Да
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    decline() {
      this.$emit('decline')
    },

    confirm() {
      this.$emit('confirm')
    },
  }
}
</script>

<style scoped lang="scss">
.heading {
  font-size: 18px;
}
</style>
