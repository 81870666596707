<template>
  <v-form @submit.prevent="onSubmit" v-show="!!id ? route.id : true">
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field label="Название" v-model="route.name"></v-text-field>
      </v-col>

      <!--
      <v-col
        cols="12"
        md="3"
      >
        <v-select
            :items="districts.map(item => ({ value: item.id, text: item.name }))"
            label="Районы"
            v-model="query.district_id"
            multiple
        />
      </v-col>
      -->

      <v-col cols="12" md="3">
        <Datepicker
          title="Дата поверки"
          clearable
          v-model="route.date"
          :is-new="!id"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          :items="regions.map((item) => ({ value: item.id, text: item.name }))"
          label="Регион"
          v-model="route.region_id"
          clearable
          @change="route.verifier_id = null"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :items="verifiers.filter(v => route.region_id === v.region_id).map((item) => ({ value: item.id, text: item.name }))"
          label="Поверитель"
          v-model="route.verifier_id"
          clearable
          :disabled="!route.region_id"
        />
      </v-col>
    </v-row>

    <v-row>
      <!--
      <v-col
          cols="12"
          md="9"
      >
        <GmapMap
            :center="{ lat: 54.6095, lng: 39.7126 }"
            :zoom="10"
            map-type-id="roadmap"
            style="width: 100%; height: 700px"
            ref="gmap"
        >
          <GmapCluster
              :zoom-on-click="true"
          >
            <GmapMarker
                v-for="(m, index) in verifications"
                :key="`verification_${index}`"
                :position="{ lat: parseFloat(m.lat) ? parseFloat(m.lat) : 54.6095, lng: parseFloat(m.lng) ? parseFloat(m.lng) : 39.7126 }"
                :clickable="true"
                @click="openInfoWindowTemplate(m)"
                :label="m.address"
                :title="m.address"
                :opacity="isVerSelected(m.id) ? 1 : 0.5"
            />
          </GmapCluster>
          <GmapInfoWindow
              :options="{ maxWidth: 300 }"
              :position="infoWindow.position"
              :opened="infoWindow.open"
              @closeclick="infoWindow.open=false"
          >
            <div style="padding: 6px;">
              <div v-html="infoWindow.template"></div>
              <div
                  class="pt-3"
              >
                <v-btn
                    @click="toggleSelected"
                >
                  {{ isVerSelected(infoWindow.id) ? 'Убрать' : 'Добавить' }}
                </v-btn>
              </div>
            </div>
          </GmapInfoWindow>
        </GmapMap>
      </v-col>
      -->

      <v-col cols="12" md="9">
        <v-list>
          <v-list-item
            v-for="item in selected"
            three-line
            :key="`item_${item.id}`"
            :class="!item.lat ? 'red lighten-4' : ''"
          >
            <v-list-item-content>
              <v-list-item-subtitle>
                Адрес: {{ item.address }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Комментарий: {{ item.comment }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Телефон: {{ item.phone }}
              </v-list-item-subtitle>

              <v-list-item-subtitle v-if="item.client_status">
                Статус заказчика: {{ item.client_status.name }}
              </v-list-item-subtitle>

              <v-list-item-subtitle v-if="item.verifier">
                Поверитель: {{ item.verifier.name }}
              </v-list-item-subtitle>

              <v-list-item-subtitle v-if="item.mechanic">
                Слесарь: {{ item.mechanic.name }}
              </v-list-item-subtitle>

              <v-list-item-subtitle v-if="item.check_date">
                Следующая поверка: {{ item.check_date }}
              </v-list-item-subtitle>

              <v-list-item-subtitle v-if="item.type_id === 1 && item.gas_meter">
                Марка счётчика: {{ item.gas_meter.brand }}
              </v-list-item-subtitle>

              <div v-if="!item.lat || !item.lng">
                <v-text-field
                  label="Широта"
                  v-model="item.new_lat"
                  :rules="[
                    (v) =>
                      !v ||
                      /^\d{2}.\d{2,8}$/.test(v) ||
                      'Некорректный формат. Пример 54.6095',
                    (v) =>
                      parseFloat(v) < 90 ||
                      'Некорректный формат. Пример 54.6095',
                  ]"
                />

                <v-text-field
                  label="Долгота"
                  v-model="item.new_lng"
                  :rules="[
                    (v) =>
                      !v ||
                      /^\d{2,3}.\d{2,8}$/.test(v) ||
                      'Некорректный формат. Пример 54.6095',
                    (v) =>
                      parseFloat(v) < 180 ||
                      'Некорректный формат. Пример 54.6095',
                  ]"
                />

                <v-btn @click="updateCoordinates(item)"> Сохранить </v-btn>
              </div>

              <v-divider class="mt-5" />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="text-right">
          <v-btn
            outlined
            color="blue"
            type="submit"
            class="mb-8 mt-10"
            :loading="routeSaving"
            :disabled="!route.name || !route.date || !route.region_id || routeSaving"
          >
            Сохранить
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
Vue.use(VueMask)

import cloneDeep from "lodash/cloneDeep";
import Datepicker from "@/components/Datepicker";
import {
  ListsService,
  RoutesService,
  VerificationsService,
} from "@/common/api.service";
import { gmapApi } from "vue2-google-maps";

export default {
  components: {
    Datepicker,
  },

  props: {
    id: {
      default: false,
    }
  },

  data() {
    return {
      routeSaving: false,
      emptyState: {
        date: new Date().toISOString().substr(0, 10),
        verifier_id: null,
        verifications: [],
      },
      route: null,
      districts: [],
      regions: [],
      verifiers: [],
      query: {
        district_id: [],
        route_id: this.id,
      },
      verifications: [],
      // Выбранные поверки.
      selected: [],

      infoWindow: {
        id: 0,
        position: { lat: 0, lng: 0 },
        open: false,
        template: "",
      },
    };
  },

  methods: {
    toggleSelected() {
      // Удаляем из выбранных если она уже там
      if (this.selected.find((item) => item.id === this.infoWindow.id)) {
        const index = this.selected
          .map(function (x) {
            return x.id;
          })
          .indexOf(this.infoWindow.id);

        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      } else {
        const verification = this.verifications.find(
          (item) => item.id === this.infoWindow.id
        );

        this.selected.push(verification);
      }
    },

    // Обновляет координаты поверки
    updateCoordinates(item) {
      VerificationsService.updateCoordinates(item.id, item).then(() => {
        item.lat = item.new_lat;
        item.lng = item.new_lng;

        this.generateBounds();
      });
    },

    openInfoWindowTemplate(item) {
      this.infoWindow.id = item.id;
      this.infoWindow.template = this.buildInfoData(item);
      this.infoWindow.position = {
        lat: parseFloat(item.lat),
        lng: parseFloat(item.lng),
      };
      this.infoWindow.open = true;
    },

    buildInfoData(item) {
      let result = "";

      if (item.address) {
        result += "Адрес: " + item.address + "<br>";
      }

      if (item.comment) {
        result += "Комментарий: " + item.comment + "<br>";
      }

      if (item.phone) {
        result += "Телефон: " + item.phone + "<br>";
      }

      return result;
    },

    isVerSelected(id) {
      return !!this.selected.find((item) => item.id === id);
    },

    addressSelected(suggestion) {
      this.verification.address = suggestion.value;

      if (suggestion.data.city) {
        this.verification.city = suggestion.data.city;
      } else if (suggestion.data.settlement) {
        this.verification.city = suggestion.data.settlement;
      }

      this.verification.street = suggestion.data.street;
      this.verification.house = suggestion.data.house;
      this.verification.flat = suggestion.data.flat;
      this.verification.lat = suggestion.data.geo_lat;
      this.verification.lng = suggestion.data.geo_lon;
    },

    onSubmit() {
      this.routeSaving = true
      this.route.selected = this.selected;

      // Update
      if (this.id) {
        RoutesService.update(this.id, this.route)
          .then(() => {
            this.resetState();
            this.$router.push("/manager/routes")
            this.$notification("Успешно сохранено")
          })
          .catch(() => {
            this.$notification("Ошибка сохранения")
          })
          .finally(() => {
            this.routeSaving = false
          })
      } else {
        // Add
        RoutesService.add(this.route)
          .then(() => {
            this.resetState();
            this.$router.push("/manager/routes")
            this.$notification("Успешно сохранено");
          })
          .catch(() => {
            this.$notification("Ошибка сохранения");
          })
          .finally(() => {
            this.routeSaving = false
          })
      }
    },

    resetState() {
      this.route = cloneDeep(this.emptyState);
    },

    clearVerifierId() {
      this.route.verifier = null;
      this.route.verifier_id = null;
    },

    generateBounds() {
      if (!this.google) {
        return;
      }

      const bounds = new window.google.maps.LatLngBounds();

      for (let m of this.verifications) {
        bounds.extend({
          lat: parseFloat(m.lat) ? parseFloat(m.lat) : 54.6095,
          lng: parseFloat(m.lng) ? parseFloat(m.lng) : 39.7126,
        });
      }

      // TODO. Не работает, разобраться
      // this.$refs.gmap.$mapObject.fitBounds(bounds)
      // this.$refs.gmap.$mapObject.panToBounds(bounds)
    },

    // Если у разных записей точно совпадают координаты
    // немного меняем их, чтобы коректно отображались на карте
    fixDuplicatedCoordinates(verifications) {
      let result = [];

      for (let i = 0; i < verifications.length; i++) {
        for (let j = i + 1; j < verifications.length; j++) {
          if (
            verifications[i].lat === verifications[j].lat &&
            verifications[i].lng === verifications[j].lng
          ) {
            verifications[j].lat = (
              parseFloat(verifications[j].lat) + 0.00005
            ).toString();
          }
        }

        result.push(verifications[i]);
      }

      return result;
    },
  },

  beforeMount() {
    this.resetState();

    ListsService.get(['districts', 'regions', 'workers']).then(({ data }) => {
      this.regions = data.regions
      this.districts = data.districts
      this.meter_statuses = data.meter_statuses
      this.verifiers = data.workers.filter((w) => w.worker_type_id === 1)
    })

    if (this.id) {
      RoutesService.get(this.id).then((response) => {
        this.route = cloneDeep(response.route);

        this.selected = cloneDeep(this.route.verifications);

        this.selected.forEach((route) => {
          this.query.district_id.push(route.district_id);
        });

        VerificationsService.get(false, this.query).then(({ data }) => {
          this.verifications = this.fixDuplicatedCoordinates(
            data.verifications
          );
          // this.selected = cloneDeep(data.verifications)

          this.generateBounds();
        });
      });
    } else {
      /*
      VerificationsService
          .get(false, this.query)
          .then(({data}) => {
            this.verifications = this.fixDuplicatedCoordinates(data.verifications)
            this.selected = cloneDeep(data.verifications)

            this.generateBounds()
          })
      */
    }
  },

  watch: {
    query: {
      handler() {
        VerificationsService.get(false, this.query).then(({ data }) => {
          this.verifications = this.fixDuplicatedCoordinates(
            data.verifications
          );

          if (!this.id) {
            this.selected = cloneDeep(data.verifications);
          }

          this.generateBounds();
        });
      },

      deep: true,
    },
  },

  computed: {
    google: gmapApi,
  },
};
</script>

<style lang="scss">
.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;

  &__item {
    box-sizing: border-box;
    max-width: calc(20% - 16px);
    margin-right: 16px;
    margin-bottom: 16px;

    @media (max-width: 600px) {
      max-width: calc(50% - 16px);
    }
  }
}

.overlay {
  &__image {
    max-width: 80vw;
    max-height: 80vh;
    margin-bottom: 16px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
  }

  &__button {
    margin: 0 auto;
  }
}

.vue-dadata {
  width: 100%;
}

.vue-dadata__input {
  font-size: 16px !important;
  width: 100% !important;
  outline: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  transition: 0.3s !important;
  box-sizing: border-box !important;
  padding: 0 5px !important;
}

.vue-dadata__input:focus {
  box-shadow: none !important;
}

.gm-ui-hover-effect {
  width: 40px !important;
}

.gm-ui-hover-effect > img {
  width: 24px !important;
  height: 24px !important;
}

.breakTitle {
  white-space: break-spaces !important;
}
</style>
